export default function TxList({ txs }) {
    if (txs.length === 0) return null;
  
    return (
      <>
        {txs.map((item) => (
          <div key={item} className="alert alert-info mt-5">
            <div className="flex-1" style={{fontSize:10+'px'}}>
              <p>Processing transaction, please wait...</p>
              <label>txid: {item.hash}</label>
            </div>
          </div>
        ))}
      </>
    );
  }
  