import Typewriter from 'typewriter-effect'
import { useHistory } from "react-router"
import Meta from '../../components/Meta'
import "./Start.css"

import expartIntroBackground from '../../assets/expart-intro-background.png'
import expartLogo3 from '../../assets/Logo3.png'

const Start = ({ playMusic, pause, playTypingSound, stop }) => {
  // page content
  const pageTitle = 'Expart'
  playTypingSound()
  pause()

  const history = useHistory()
  // const [playTypingSound, {isPlaying, stop}] = useSound(typingSound)

  const pauseTypingSound = () => {
    stop()
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="expart-introduction-mainbox">
        <img className="expart-introduction-background" src={expartIntroBackground} alt={""} />
        <div className="typewriter-effect-box">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString('IT\'S TIME TO SHOW YOUR SKILLS ON WHO IS THE ONE TRUE EXPART!')
                .pauseFor(100)
                .typeString('<br/> REMEMBER EXPART, YOU WERE BORN FOR THIS!')
                .start()
            }}
            style={{ fontSize: "25px", width: "80%" }}
          />
        </div>
        <button type="button" className="next nes-btn is-success is-large next-button button-new-styles" onClick={
          () => {
            history.push("/trial")
            pauseTypingSound()
            playMusic()
          }
        }>ENTER</button>
        <button type="button" className="rules nes-btn is-success is-large next-button button-new-styles" onClick={
          () => {
            pauseTypingSound()
            history.push("/rules");
          }
        }>GAME RULES</button>
        <img className="expartLogo3" src={expartLogo3} alt={""} />
      </div>
    </div>
  )
}

export default Start