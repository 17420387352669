import { useEffect, useState } from 'react'
import rank0 from '../../assets/rank_1.png'
import rank1 from '../../assets/rank_2.png'
import rank2 from '../../assets/rank_3.png'
import rank3 from '../../assets/rank_4.png'
import rank4 from '../../assets/rank_5.png'
import rank5 from '../../assets/rank_6.png'
import rank6 from '../../assets/rank_7.png'

import './RankPicScroll.css'

const RankPicScroll = ({ score }) => {

    const [imageForRank, setImageForRank] = useState(rank0)

    const styleForRankPicScroll = {
        height: "150px",
        width: "150px",
    }

    useEffect( ()=>{
        if (score !== 0) {
            setTimeout(() => {

                if (score <= 5){
                    setImageForRank(rank0)
                }
                if (score <= 10){
                    setImageForRank(rank1)
                }
                else if(score <= 20){
                    setImageForRank(rank2)
                }
                else if(score <= 30){
                    setImageForRank(rank3)
                }
                else if(score <= 40){
                    setImageForRank(rank4)
                }
                else if(score <= 50){
                    setImageForRank(rank5)
                }
                else if(score > 50){
                    setImageForRank(rank6)
                }else{
                    setImageForRank(rank0)
                }
              
            }, 1000)
        }
    }, [score])
    
    return (
        <div className="bounce-image-box">
            <img src={imageForRank} alt={""} style={styleForRankPicScroll} className="bounce-in-bottom"/>
        </div>
    )
}

export default RankPicScroll
