import axios from 'axios';

const url = 'https://us-central1-brother-nft.cloudfunctions.net/expart-me-backend';
const scoreurl = 'https://us-central1-brother-nft.cloudfunctions.net/expart-me-scores'
const shopurl = 'https://us-central1-brother-nft.cloudfunctions.net/expart-me-shop'
const profiledataurl = 'https://us-central1-brother-nft.cloudfunctions.net/expart-status'

export const createGameSession = async (username) => {
    const data = await axios.get(`${url}`, {
        params: { i: username, f: 'c'}
    })
    return data
}

export const getNextNFTs = async (username) => {
    const data = await axios.get(`${url}`, {
        params: { i: username, f: 'n'}
    })
    return data
}

export const checkAnswer = async (username, selection) => {
    const data = await axios.get(`${url}`, {
        params: { i: username, f: 'a', a: selection}
    })
    return data
}

export const updateScores = async () => {
    const data = await axios.get(`${scoreurl}?f=updatetopscores`)
    return data
}
// setscores vs updatescores
export const setScores = async (userid) => {
    const data = await axios.get(`${scoreurl}?f=setscore&userid=${userid}`)
    return data
}
export const getScores = async () => {
    const data = await axios.get(`${scoreurl}?f=gettopscores`)
    return data
}

export const buyLives = async (username,txid) =>{
    const data = await axios.get(`${shopurl}`, {
        params: { i: username, f: 'b', t: txid}
    })
    return data
}

export const getLives = async (username) =>{
    const response = await axios.get(`${shopurl}`, {
        params: { i: username, f: 'w'}
    })
    response.data = response.data.replace(/'/g, '"')
    return response
}

export const getBucks= async (username) =>{
    const response = await axios.get(`${shopurl}`, {
        params: { i: username, f: 'u'}
    })
    response.data = response.data.replace(/'/g, '"')
    return response
}

export const getProfileData= async (username) =>{
    const response = await axios.get(`${profiledataurl}`, {
        params: { i: username, f: 'p'}
    })
    return response.data
}
// Setname api failing
export const setName= async (userid,name) =>{
    const response = await axios.get(`${profiledataurl}`, {
        params: { id: userid,name:name, f: 'setusername'}
    })
    return response.data
}
export const getName= async (userid) =>{
    const response = await axios.get(`${profiledataurl}`, {
        params: { id: userid, f: 'getusername'}
    })
    return response.data
}