import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({ isAuth, component: Component, ...rest }) => {

    return (
        <Route {...rest}
            render={(props) => {
                if (isAuth) {
                    return <Component {...rest}/>
                } else {
                   return <Redirect to={{ pathname: "/enter", state: { from: props.location } }} />
                }
            }}
        />
    );
};

export default ProtectedRoute;