import { useMetaMask } from "metamask-react"

import "./Menu.css"
import { useState } from 'react'

import { addToNetwork } from './Harmony'

const Menu = () => {
  const { status, connect } = useMetaMask()
  const [open, setOpen] = useState(false)

  const moveToProfile = () => {
    window.location.replace('#/profile')
  }
  const handleLogin = () => {
    if (status === "unavailable") {
      setOpen(true)
    } else {
      connect()
        .then(() => { 
          addToNetwork()
        })
    }
  }

  return (
    <header>
      <div className="menu-container">
        <div className="nes-select is-dark left-directory">
          <select required id="dark_select" value={'DEFAULT'}
            onChange={(e) => {
            const selected = e.target.value
            window.location.replace('#/' + selected)
            e.target.value = ""
          }
          }>
            <option value="DEFAULT" disabled hidden>DIRECTORY</option>
            <option value="enter" >PLAY</option>
            <option value="summary" >HISTORY</option>
            <option value="rankings">HIGH SCORES</option>
            <option value="about">ABOUT</option>
            <option value="shop">SHOP</option>
            <option value="how-to-use-metamask">SETUP METAMASK</option>
            <option value="privacy-tos">PRIVACY/TOS</option>
          </select>
        </div>
        <div className="right-profile">
          {
            (status === "connected") ?
              (
                <span className="wallet-profile-box" onClick={moveToProfile}>PROFILE</span>
              ) :
              (<span className="wallet-profile-box" onClick={handleLogin}> CONNECT <br />WALLET</span>)
          }
        </div>
      </div>

      {
        open &&
        <div className="metamask-unavailability-dialog">
          <p className="menu-dialog-title">Metamask Unavailable</p>
          <p>Metamask is not available in this browser.</p>
          <button className="nes-btn" onClick={() => { setOpen(false) }}>Okay</button>
        </div>
      }

      {/* <dialog className="nes-dialog metamask-unsupport-dialog" id="dialog-default" open={open}>
        <form method="dialog">
          <p className="title">Metamask Unavaialable</p>
          <p>Metamask is unavailable for this browser.</p>
          <menu className="dialog-menu">
            <button className="nes-btn" onClick={() => { setOpen(false) }}>Okay</button>
          </menu>
        </form>
      </dialog> */}
    </header>
  )
}

export default Menu
