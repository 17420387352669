import './Shop.css'
import { useState, useEffect } from "react";
import * as api from '../../api/index'
import SuccessMessage from './SucessMessage';

import dollar from '../../assets/Dollar.png'

import { SyncOutlined } from '@ant-design/icons';

export default function Recharge({ userid}) {

  const [lives, setLives] = useState('loading...')
  const [smsg, setSmsg] = useState()
  const [recharged, setRecharged] = useState()
  const [maxLives, setMaxLives] = useState()
  //Only call api once
  const [once, setOnce] = useState()
  useEffect(() => {
    if (userid && !once) {
      setOnce(true)
      updateCurrentLives()
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const updateCurrentLives = async(e) =>{
    api.getLives(userid).then((response) => {
      let responseObj = JSON.parse(response.data)
      let lives = responseObj.c
      if (lives) {
        setLives(lives)
        setMaxLives(lives > 5 ? 5 : lives)
      } else {
        setLives("No life found...")
      }
    })
  }
  const rechargeBucks = async (e) => {
    e.preventDefault();
    setRecharged(true)
    await api.getBucks(userid).then((response) => {
      let livesLeft = lives - 1;
      setLives(livesLeft)
      let responseObj = JSON.parse(response.data)
      setSmsg(responseObj.s)
    })
  }
  async function simulateMouseClick(el) {
    let opts = {view: window, bubbles: true, cancelable: true, buttons: 1};
    el.dispatchEvent(new MouseEvent("mousedown", opts));
    await new Promise(r => setTimeout(r, 50));
    el.dispatchEvent(new MouseEvent("mouseup", opts));
    el.dispatchEvent(new MouseEvent("click", opts));
  }
  const buyNow = async(e)=>{
    let btn = document.getElementById("buy-now");
    await simulateMouseClick(btn);
  }
  return (
    <div className='shop-box buy-lives-box'>
      <form>
        <h2 className='mb-3'>Recharge bucks</h2>
        <p className='user-id-shop'>User: {userid}</p>
        <p>1 life == 3 expart bucks. No refunds.</p>
        <p>lives remaining: {lives} <span id="refresh-lives" onClick={updateCurrentLives}><SyncOutlined/></span></p>

        <div className='extra-life-box'>
          {
            lives === 'loading...' ? <p>NO EXTRA LIFE</p> : ''
          }
          {
            // Once lives are bought
            // true would be changed tolives != 'loading...'
            true ?
            <div className='extra-life-list'>
              {
                !recharged && lives > 0 ?
                // there would be 'parseInt(lives)' instead of 5
                [...Array(maxLives)].map((e, i) => <div className="life-card-shop" key={i}>
                   <p className='life-card-shop-extra'>EXTRA</p>
                   <p className='life-card-shop-life'>LIFE</p>
                    <img src={dollar} alt={''}/>
                    <br/>
                    <button className="nes-btn is-warning is-small nes-shop-apply-now" onClick={rechargeBucks}>APPLY NOW</button>
                </div>)
                :
                <div className="life-card-shop">
                   <p className='life-card-shop-extra'>EXTRA</p>
                   <p className='life-card-shop-life'>LIFE</p>
                    <img src={dollar} alt={''}/>
                    <br/>
                    <button className="nes-btn is-warning is-small nes-shop-apply-now" onClick={buyNow}>BUY NOW</button>
                </div>
              }
            </div> :
            ''
          }
        </div>

      </form>
      <SuccessMessage message={smsg} />
    </div>
  );
}
