import { useState } from "react";
import { ethers } from "ethers";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SucessMessage";
import Recharge from "./Recharge";
import TxList from "./TxList";

import './Shop.css'

import * as api from '../../api/index'

const startPayment = async ({setError, setTxs, setSmsg, ether, addr}) => {
  try{
    if (!window.ethereum){
      throw new Error ('No crypto wallet found.  Please install one.')
    }
    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    provider.getNetwork().then(async (network)=>{

      if (network.chainId !== '1666600000'){
        setError("You must be Harmony Mainnet Shard 0")
      }
      else{
        try{
          const signer = provider.getSigner();
          ethers.utils.getAddress(addr);
          
          const tx = await signer.sendTransaction({
            to: addr,
            value: ethers.utils.parseEther(ether)
          })
          // console.log({ether,addr})
          // console.log("tx",tx);
          setTxs([tx]);

          let account = await signer.getAddress();

          let purchaseComplete = await api.buyLives(account,tx.hash)
          let msg = JSON.parse(purchaseComplete.data.replace(/'/g, '"'))  // eslint-disable-line no-unused-vars
          // console.log(msg)
          async function simulateMouseClick(el) {
            let opts = {view: window, bubbles: true, cancelable: true, buttons: 1};
            el.dispatchEvent(new MouseEvent("mousedown", opts));
            await new Promise(r => setTimeout(r, 50));
            el.dispatchEvent(new MouseEvent("mouseup", opts));
            el.dispatchEvent(new MouseEvent("click", opts));
          }
          let btn = document.getElementById("refresh-lives");
          await simulateMouseClick(btn);


          setSmsg("Credit(s) loaded.")
          setTxs([]);

        }catch (error){
            setError(error.message)
        }
      }

    })
  }catch (error){
    setError(error.message)
  }
};


const Shop = () => {
  const [error, setError] = useState();
  const [smsg, setSmsg] = useState();
  const [txs, setTxs] = useState([]);
  const [account, setAccount] = useState();

  const provider = new ethers.providers.Web3Provider(window.ethereum)
  provider.getNetwork().then(async ()=>{
    const signer = provider.getSigner();
    let account = await signer.getAddress();
    setAccount(account)
  })

  const handleSubmit = async(e)=>{



    e.preventDefault();
    const data = new FormData(e.target);
    setError();
    await startPayment({
      setError,
      setTxs,
      setSmsg,
      ether: data.get("lives"),
      addr: '0x579D209Cf5F7d40FCcdE4E7050FefA25BAFCc596',
    });
  }
  const max = 100;  // eslint-disable-line no-unused-vars
  const min = 1;  // eslint-disable-line no-unused-vars
  const step = 1;  // eslint-disable-line no-unused-vars
  return (
    
      <div className="expart-shop-page">
      <h1 className="shop-title-at-top">EXPART SHOP</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <ErrorMessage message={error} />
          <TxList txs={txs} />
          <SuccessMessage message={smsg} />
        </div>
        <main className="shop-box buy-lives-box">
          <h1 className="mb-3">Buy Lives</h1>
          <h6>Warning, only use Harmony ONE.  No refunds.</h6>
          <h6 className="mb-4">1 ONE == 1 Expart LIFE</h6>
          <div className="mb-2">
            <label htmlFor="quantity">Quantity (between 1 and 100):</label>
            <input type="number" id="lives" name="lives" defaultValue="1" min="1" max="100"/>
          </div>
          <button type="submit" id="buy-now" className="nes-btn is-success is-large">Buy Now</button>
        </main>
        <hr></hr>
        </form>
        <Recharge userid={account}/>
        
        <hr></hr>
      </div>

    
  )
}

export default Shop