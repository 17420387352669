import { Route } from "react-router-dom";

const ProtectedRoute = ({ isAuth, component: Component, ...rest }) => {

    return (
        <Route {...rest}
            render={(props) => {
                if (isAuth) {
                    return <Component {...rest}/>
                } else {
                   return (
                       <div style={{height:"100vh", display:"flex", textAlign:"center", alignItems:"center"}}>
                           {
                               (rest.fornext === "summary") ?
                               <h1>CONNECT YOUR WALLET TO SAVE AND SEE YOUR HISTORY.</h1> :
                               <h1>CONNECT YOUR WALLET TO SHOP.</h1>
                           }
                       </div>
                   )
                }
            }}
        />
    );
};

export default ProtectedRoute;