import rank0 from '../../assets/rank_1.png'
import rank1 from '../../assets/rank_2.png'
import rank2 from '../../assets/rank_3.png'
import rank3 from '../../assets/rank_4.png'
import rank4 from '../../assets/rank_5.png'
import rank5 from '../../assets/rank_6.png'
import rank6 from '../../assets/rank_7.png'

const RankPic = ({score}) => {
    
    const styleForRankPic = {
        maxWidth: "200px",
        maxHeight: "200px"
    }

    if (score <= 5){
        return <img src={rank0} alt={""} style={styleForRankPic}/>
    }
    if (score <= 10){
        return <img src={rank1} alt={""} style={styleForRankPic}/>
    }
    else if(score <= 20){
        return <img src={rank2} alt={""} style={styleForRankPic}/>
    }
    else if(score <= 30){
        return <img src={rank3} alt={""} style={styleForRankPic}/>
    }
    else if(score <= 40){
        return <img src={rank4} alt={""} style={styleForRankPic}/>
    }
    else if(score <= 50){
        return <img src={rank5} alt={""} style={styleForRankPic}/>
    }
    else if(score > 50){
        return <img src={rank6} alt={""} style={styleForRankPic}/>
    }else{
        return <img src={rank0} alt={""} style={styleForRankPic}/>
    }

}

export default RankPic
