export const addToNetwork = async () => {
    const params = {
        chainId: toHex(chain.chainId), // A 0x-prefixed hexadecimal string
        chainName: chain.name,
        nativeCurrency: {
            name: chain.nativeCurrency.name,
            symbol: chain.nativeCurrency.symbol, // 2-6 characters long
            decimals: chain.nativeCurrency.decimals,
        },
        rpcUrls: chain.rpc,
        blockExplorerUrls: [((chain.explorers && chain.explorers.length > 0 && chain.explorers[0].url) ? chain.explorers[0].url : chain.infoURL)]
    }

    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [params, accounts[0]],
    })
        .then((result) => {
            console.log(result)
        })
        .catch((error) => {
            console.log(error)
        });
}

const chain = {
    "name": "Harmony Mainnet Shard 0",
    "chain": "Harmony",
    "network": "mainnet",
    "rpc": ["https://api.harmony.one"],
    "faucets": ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
    "nativeCurrency": { "name": "ONE", "symbol": "ONE", "decimals": 18 },
    "infoURL": "https://www.harmony.one/",
    "shortName": "hmy-s0",
    "chainId": 1666600000,
    "networkId": 1666600000,
    "explorers": [{ "name": "Harmony Block Explorer", "url": "https://explorer.harmony.one", "standard": "EIP3091" }]
}
const toHex = (num) => {
    return '0x' + num.toString(16)
}