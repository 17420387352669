import React from "react";
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import Meta from '../components/Meta'
import "./GameScreen.css"

import gameBackground from '../assets/game-background.png'
import imageBottom from '../assets/image-bottom.PNG'
import clock from '../assets/clock.gif'


const TrialGameScreen = (
    { pageTitle, timer, roundOver, roundResult, level, guess, playNextGame, playRealGame, infoshown, setInfoshown }
) => {

    return (
        // <div className={`${ isMobile ? 'landscapeview' : ''}`}>
        <div >
            <Meta title={pageTitle} />
            <DeviceOrientation lockOrientation={'landscape'}>
                {/* Will only be in DOM in landscape */}
                <Orientation orientation='landscape' alwaysRender={false}>
                    <div>
                        <div className="expart-maingame-screen">
                            <div className="expart-maingame-background">
                                <img className="expart-maingame-background-image" src={gameBackground} alt={""} />
                            </div>
                            <div className="game-components">
                                <div style={{ textAlign: "center" }}>
                                    <h2 className='trial-how-to-play'>HOW TO PLAY</h2>
                                    <p className='trial-timer'>TIMER: {timer}</p>
                                </div>

                                <div className="game-image-row">
                                    <div className="nft-image-container">
                                        <img onClick={guess} src={level.l.i} alt={""} className="game-main-nft" />
                                        <br />
                                        <img src={imageBottom} alt={""} className="game-nft-bottom-image" />
                                    </div>
                                    <div className={`nft-image-container ${infoshown === true ? 'for-shadow-highlight' : ''}`}>
                                        <img onClick={guess} src={level.r.i} alt={""} className="game-main-nft rightItem" />
                                        <br />
                                        <img src={imageBottom} alt={""} className="game-nft-bottom-image" />
                                    </div>
                                </div>
                                {
                                    roundOver &&
                                    <div className="game-result-next-button"
                                        style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -30%)"}}>
                                        <h2>{roundResult === "r"
                                            ? <span className="nes-text is-success">Correct</span>
                                            : <span className="nes-text is-error">Sorry</span>
                                        }</h2>
                                        <br />
                                        <button onClick={playNextGame} className="nes-btn is-primary is-large game-next-button">Next</button>
                                        <br /><br />
                                        <button onClick={playRealGame} className="nes-btn is-success is-large game-next-button">END TRIAL & PLAY GAME</button>
                                    </div>

                                }
                                <div className="clock-image-container">
                                    <img src={clock} alt={""} />
                                </div>
                            </div>
                        </div>
                        <dialog className="nes-dialog trial-game-info" id="dialog-default" open={infoshown}>
                            <form method="dialog" className="trial-game-info-form">
                                <h1 className="title">MY EXPART QUALIFICATIONS TELL ME THIS ONE IS WORTH MORE</h1>
                                <br />
                                <menu className="dialog-menu">
                                    <button className="nes-btn is-primary" onClick={() => { setInfoshown(false); playNextGame(); }} style={{ marginRight: "50px" }}>Okay</button>
                                    <button onClick={playRealGame} className="nes-btn is-success is-large game-next-button">SKIP TRIAL</button>
                                </menu>
                            </form>
                        </dialog>
                    </div>
                </Orientation>
                {/* Will stay in DOM, but is only visible in portrait */}
                <Orientation orientation='portrait' alwaysRender={false}>
                    <div style={{height:"100vh", display:"flex", textAlign:"center", alignItems:"center"}}>
                        <h2>PLEASE ROTATE YOUR DEVICE TO PLAY THE GAME.</h2>
                    </div>
                </Orientation>
            </DeviceOrientation>
        </div>
    )
}

export default TrialGameScreen