import { Container } from 'react-bootstrap'
import { useHistory } from "react-router"
import Meta from '../../components/Meta'

import './Rules.css'

const Rules = ({playMusic, pause}) => {
  // page content
  const pageTitle = 'Expart Rules'
  pause()

  const history = useHistory()

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="text-page-top-margin">
        <Container><h1 className="text-center">EXPART <br /> GAME RULES</h1>
          <h3>How it works</h3>
          <p>The game featurestwo non-fungible tokens (NFTs) side by side at random , please select which you believe is more expensive.</p>

          <p>Each correct guess enters one (1) raffle ticket towards the weekly featured giveaway NFT shown at the end of the game. Streak bonuses are also in effect, three correct guesses in a row adds one extra raffe, five correct guesses in a row gets two raffles. Ten straight will get give extra raffles.</p>

          <p>Users must sign in / connect wallet into Metamask in order to claim raffles! All users and guests are allowed free-play up to three lives. <br /> Users that have connected wallets are able to earn two extra lives. To claim this, invite two friends here. Leave a comment on the featured NFT to earn an additional life.
          </p>

          <p>You have (5) seconds to make your selection, if timer runs out default left side will be selected. Your score(s) will be displayed on our site, all time high score earners will earn a special prize at the end of each period (TBA). Please check time to time for future details. The game will conclude when all lives are gone, you will be redirected to the Game Summary Page.</p>
          <p>At EXPART our mission is to introduce you to the world of NFTs, our goal is not to tell you which NFT is better. That is your job, you being the EXPART :)</p>

          <div className="text-center" style={{margin:"20px auto 40px"}}>
          <button type="button" className="nes-btn is-success is-large button-new-styles"
              onClick={() => { 
                playMusic()
                history.push("/trial") 
              }}
          >PLAY GAME</button>
          </div>

        </Container>

      </div>
    </div>
  )
}

export default Rules