import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import useSound  from 'use-sound';

import { data } from "../mockData";
import TrialGameScreen from "./TrialGameScreen";
import wrongSound from '../assets/sounds/Wrong.wav'
import correctSound from '../assets/sounds/correct.mp3'

const Trial = () => {
    // page content
    const pageTitle = 'Expart Game'
    const [playWrongSound] = useSound(wrongSound)
    const [playCorrectSound] = useSound(correctSound)

    const [infoshown, setInfoshown] = useState(true)

    const [timer, setTimer] = useState(10);

    // let randomNo = Math.floor(Math.random() * 7) + 1;
    const [levelId, setLevelId] = useState(2);
    const [level, setLevel] = useState(data[levelId]);
    const [roundOver, setRoundOver] = useState(false);
    const [roundResult, setRoundResult] = useState('r');

    const history = useHistory();

    const guess = (selection) => {
        let correctAns = level.s

        let selectedAns = (selection.target.className.indexOf('rightItem') >= 0) ? 'r' : 'l'

        if (roundOver) {
            return;
        }
        evaluateRound(correctAns, selectedAns)

        setRoundOver(true)
    }

    const evaluateRound = (correctAns, selectedAns) => {
        if (selectedAns === correctAns) {
            console.log("correct")
            setRoundResult('r');
            playCorrectSound()
        } else {
            console.log("incorrect")
            setRoundResult('w');
            playWrongSound()
        }
    }

    useEffect(() => {
        if (infoshown === false){
            const time = (timer > 0 && !roundOver) && setInterval(() => setTimer(timer - 1), 1000);
            return () => clearInterval(time);
        }
    });
    useEffect(() => {
        if (timer === 0 && !roundOver) {
            let correctAns = level.s
            let selectedAns = 'l'

            if (roundOver) {
                return;
            }
            evaluateRound(correctAns, selectedAns)
            setRoundOver(true)
        }
    }, [timer]); // eslint-disable-line react-hooks/exhaustive-deps

    const playNextGame = () => {
        if( levelId < 7){
            setLevelId(levelId => levelId + 1)
            //console.log(levelId)
            setLevel(data[levelId])
            setRoundOver(false)
            setTimer(10)
        }
        else{
            let randomNo = 1
            setLevelId(randomNo)
            setLevel(data[levelId])
            setRoundOver(false)
            setTimer(10)
        }
    }

    const playRealGame = () => {
        history.push("/game");
    }

    return (
        <div>
            <TrialGameScreen 
                pageTitle={pageTitle}
                timer={timer}
                roundOver={roundOver}
                roundResult={roundResult}
                level={level}
                guess={guess}
                playRealGame={playRealGame}
                playNextGame={playNextGame}
                infoshown={infoshown}
                setInfoshown={setInfoshown}
            />
            
        </div>
    )
}

export default Trial