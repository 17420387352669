import Meta from '../components/Meta'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import "./GameScreen.css"

import gameBackground from '../assets/game-background.png'
import dollar from '../assets/Dollar.png'
import imageBottom from '../assets/image-bottom.PNG'
import clock from '../assets/clock.gif'
import Warning from './../components/Warning/Warning'
import BuyMoreLives from './../components/BuyMoreLives/BuyMoreLives'
// import { isMobile } from 'react-device-detect'

const GameScreen = (
    { playMusic, pauseMusic, pageTitle, life, score, timer, roundOver, roundResult, level, gameOver, guess, seeResults, playNextGame, warningshown, closeWarning, levelPics, showHammer, showWarningTimer, warningtimer, getMoreLives, noLivesLeft }
) => {

    const lives = []
    for (let i = 0; i < life; i++) {
        lives.push(<img key={i}src={dollar} alt={""} />)
    }
    let music = true;
    const toggleMusic = () => {
        if (music) {
            pauseMusic()
            music = false
        } else {
            playMusic()
            music = true
        }
    }



    return (
        // <div className={`${ isMobile ? 'landscapeview' : ''}`}>
        <div>
            <Meta title={pageTitle} />
            <DeviceOrientation lockOrientation={'landscape'}>
                {/* Will only be in DOM in landscape */}
                <Orientation orientation='landscape' alwaysRender={false}>
                    <div>
                        <div className={`expart-maingame-screen ${showHammer === true ? 'showhammer' : ''}`}>
                            <div className="expart-maingame-background">
                                <img className="expart-maingame-background-image" src={gameBackground} alt={""} />
                            </div>
                            <div className="game-components">
                                <div className="game-top-row">
                                    <div className="dollars">
                                        {lives}
                                    </div>
                                    <div>
                                        Time: {timer}
                                    </div>
                                    <div>
                                        <span onClick={toggleMusic}>Mute </span>
                                        <span>SCORE: {score}</span>
                                    </div>
                                </div>

                                <div className="game-image-row">
                                    <div className="nft-image-container">
                                        <img onClick={guess} src={levelPics.l} alt={""} className="game-main-nft" />
                                        <br />
                                        <img src={imageBottom} alt={""} className="game-nft-bottom-image" />
                                    </div>
                                    <div className="nft-image-container">
                                        <img onClick={guess} src={levelPics.r} alt={""} className="game-main-nft rightItem" />
                                        <br />
                                        <img src={imageBottom} alt={""} className="game-nft-bottom-image" />
                                    </div>
                                </div>
                                {
                                    noLivesLeft &&
                                    <div className="game-result-next-button">
                                        <h3 className="nes-text is-warning">No more lives left: Please wait X hours</h3> <br />
                                        <button onClick={getMoreLives} className="nes-btn is-success game-next-button">Buy Lives</button>
                                    </div>
                                }
                            
                                {
                                    roundOver &&
                                    <div className='game-result-next-button-container'>
                                        <div className="game-result-next-button">
                                            <h2>{roundResult === "r"
                                                ? <span className="nes-text is-success">CORRECT</span>
                                                : <span className="nes-text is-error">
                                                    {roundResult === "s" ? "SKIPPED" : "SORRY"}
                                                </span>
                                            }</h2>
                                            <br />
                                            {
                                                roundOver && !gameOver && <button onClick={playNextGame} className="nes-btn is-primary is-large game-next-button">NEXT</button>
                                            }
                                            {
                                                gameOver &&
                                                <div>
                                                    <h3 className="nes-text is-warning">GAME OVER</h3> <br />
                                                    <button onClick={seeResults} className="nes-btn is-success is-large game-next-button">SEE RESULTS</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="clock-image-container">
                                    <img src={clock} alt={""} />
                                </div>
                            </div>
                            {
                                warningshown && <Warning closeWarning={closeWarning} />
                            }
                            {
                                noLivesLeft && <BuyMoreLives closeWarning={closeWarning} />
                            }
                            {
                                showWarningTimer &&
                                <div style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100vh",
                                    backgroundColor: "#323232bf",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    cursor: 'disabled'
                                }}>
                                    <p style={{
                                        width: "100%",
                                        fontSize: "190px",
                                        color: "white",
                                    }}
                                    >{warningtimer}</p>
                                </div>
                            }
                        </div>
                    </div>
                </Orientation>
                {/* Will stay in DOM, but is only visible in portrait */}
                <Orientation orientation='portrait' alwaysRender={false}>
                    <div style={{ height: "100vh", display: "flex", textAlign: "center", alignItems: "center" }}>
                        <h2>PLEASE ROTATE YOUR DEVICE TO PLAY THE GAME.</h2>
                    </div>
                </Orientation>
            </DeviceOrientation>
        </div>
    )
}

export default GameScreen