import { useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import MobileWarning from '../components/Warning/MobileWarning'
import Menu from '../components/Menu/Menu'

import './Layout.css'

const Layout = ({ children }) => {
  const [mobileWarningOpen, setMobileWarningOpen] = useState(true)

  return (
    <Router>
      {/* <div className={`${ isMobile ? 'landscapeview' : ''}`}> */}
        <Menu />
        <main>{children}</main>
        {
          isMobile ? <MobileWarning mobileWarningOpen={mobileWarningOpen} setMobileWarningOpen={setMobileWarningOpen} /> : null
        }
      {/* </div> */}
    </Router>
  )
}

export default Layout
