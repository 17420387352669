import { useState, useEffect } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useMetaMask } from "metamask-react"
import useSound from 'use-sound';

// Layout
import Layout from './layout/Layout'

// pages
import About from './pages/About/About'
import Rankings from './pages/Rankings/Rankings'
import Tutorial from './pages/Tutorial'
import NotFound from './pages/NotFound'
import Game from './pages/Game'
import Score from './pages/Score/Score'
import EnterExpart from './pages/EnterExpart/EnterExpart'
import Start from './pages/Start/Start'
import PrivacyTOS from './pages/PrivacyToS/PrivacyToS'
import Rules from './pages/Rules/Rules'
import Trial from './pages/Trial'
import Featured from './pages/Featured'
import ProtectedRoute from './components/Route/ProtectedRoute'
import ProtectedRoute2 from './components/Route/ProtectedRoute2'
import Profile  from './pages/Profile/Profile';
import Shop from './pages/Shop/Shop';
import UseMetamask from './pages/UseMetamask/UseMetamask';

import gameMusic from './assets/sounds/music.mp3'
import typingSound from './assets/sounds/typing.mp3'

const App = () => {
  const { status, account } = useMetaMask()
  let authenticated = (status === 'connected') ? true : false
  const [isAuth, setIsAuth] = useState(authenticated)

  // const [playMusic, {isPlaying, pause}] = useSound(gameMusic, {volume: 0.2, loop: true})
  const [playMusic, {pause}] = useSound(gameMusic, {volume: 0.2, loop: true})
  const [playTypingSound, {stop}] = useSound(typingSound)

  useEffect(() => {
    if (status === 'connected') {
      setIsAuth(true)
    }
  }, [account]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <HashRouter>
        <Switch>
            <Route exact path="/" render={() => (
              <Redirect to="/enter" />
            )} />
            <Route path='/enter' render={(props) => <EnterExpart {...props} pause={pause}/>} />
            <Route path='/start' render={(props) => <Start {...props} playMusic={playMusic} pause={pause} playTypingSound={playTypingSound} stop={stop}/>} />
            <Route path='/game' render={(props) => <Game {...props} account={account} playMusic={playMusic} pause={pause}/>} />
            <Route path='/score' render={(props) => <Score {...props} account={account} />} />
            <Route path='/rankings' render={(props) => <Rankings {...props} account={account} />} />
            <Route path='/tutorial' component={Tutorial} />
            <Route path='/about' component={About} />
            <Route path='/featured' component={Featured} />
            <Route path='/rules' render={(props) => <Rules {...props} playMusic={playMusic} pause={pause}/>} />
            <Route path='/trial' component={Trial} />
            <Route path='/how-to-use-metamask' component={UseMetamask} />
            <Route path='/privacy-tos' component={PrivacyTOS} />
            <ProtectedRoute path="/profile" component={Profile} isAuth={isAuth} account={account} />
            {/* <Route path='/shop' component={Shop} /> */}
            <ProtectedRoute2 path="/shop" component={Shop} isAuth={isAuth} account={account} />
            <ProtectedRoute2 path="/summary" component={Profile} isAuth={isAuth} account={account} fornext={"summary"}/>
            <Route path="*" component={NotFound} />
        </Switch>
      </HashRouter>
    </Layout>
  )
}

export default App
