import Meta from '../components/Meta'

import './Featured.css'

import topLight from '../assets/toplights1.PNG'
import NFTBox from '../assets/NFT_box.png'

const Featured = () => {
    // page content
    const pageTitle = 'Featured'

    return (
        <div>
            <Meta title={pageTitle} />
            <div className="expart-featured-shadow">
            </div>
                <div className="expart-featured-screen">
                    <div className="featured-top-lights text-center">
                        <img src={topLight} alt={""}/>
                    </div>
                    <div className="featured-images-section">
                        <div className="featured-nft-image-left text-left">
                            <img src="https://ipfs.infura.io/ipfs/QmcAD3T5jNaPVwNmvf1YarjzKr6434BsiWMQyZ7ij8JdaM" alt={""} className="game-main-nft" />
                            <br />
                            <p>
                                LAST WEEK NFT WINNER: MR. X <br/>
                                PRICE: 1$ <br/>
                                TOTAL PLAYERS: 100 
                            </p>
                        </div>
                        <div className="featured-nft-image-mid text-center">
                            <div className="main-featured-nft-container">     
                                <img src="https://ipfs.infura.io/ipfs/QmcAD3T5jNaPVwNmvf1YarjzKr6434BsiWMQyZ7ij8JdaM" alt={""} className="game-main-nft" />
                                <img src={NFTBox} alt={""} className="game-main-nft-box" />
                            </div>
                            
                            <br />
                            <p>
                                LAST WEEK NFT WINNER: MR. X <br/>
                                PRICE: 1$ <br/>
                                PRICE: 1$ <br/>
                                PRICE: 1$ <br/>
                                TOTAL PLAYERS: 100 
                            </p>
                        </div>
                        <div className="featured-nft-image-right text-right">
                            <img src="https://ipfs.infura.io/ipfs/QmcAD3T5jNaPVwNmvf1YarjzKr6434BsiWMQyZ7ij8JdaM" alt={""} className="game-main-nft" />
                            <br />
                            <p>
                                LAST WEEK NFT WINNER: MR. X <br/>
                                PRICE: 1$ <br/>
                                TOTAL PLAYERS: 100 
                            </p>
                        </div>
                    </div>
                </div>
            
        </div>
    )
}

export default Featured