import { useEffect, useState } from 'react'
import Meta from '../../components/Meta'
import DataAccordion from '../../components/DataAccordion/DataAccordion'
import "./Profile.css"

import expartLogo3 from '../../assets/Logo3.png'
import rightArr from '../../assets/button_matchhistory_up.png'

import * as api from '../../api/index'
// import { ResponsiveEmbed } from 'react-bootstrap'

const Profile = ({account}) => {
    // page content
    const pageTitle = 'Profile'
    const [name, setName]=useState('ExpArt')
    const [profileData, setProfileData] = useState({
        dates: [],
        score: { "a": 0, "YYYY-MM-DD": 0, "m": 0},
        state: { "s": "YYYY-MM-DD", "l": 0 },
        wallet: { "c": 0 },
        highestDailyScore: 0,
        totalGames: 0,
        correctGuessNo: 0,
        accuracy: 0
    })

    useEffect(() => {
        async function fillProfileData() {
            let response = await api.getProfileData(account)
            let {score, state, wallet, ...dates} = response
            let dailyScores = []
            let dailyRounds = []
            let totalGames = 0

            Object.keys(dates).forEach(function(key) {
                totalGames++
                // let date = key.substring(0,10)
                let dayScore = dates[key].s
                let dayRounds = dates[key].r
                dailyScores.push(dayScore)
                dailyRounds.push(dayRounds)
            })
            let highestDailyScore = Math.max(...dailyScores)
            let correctGuessNo = dailyScores.reduce((a, b) => a + b, 0)
            let totalRounds = dailyRounds.reduce((a, b) => a + b, 0)
            let accuracy = (correctGuessNo / totalRounds * 100).toFixed(2)

            setProfileData({
                dates: dates,
                score: score,
                state: state,
                wallet: wallet ? wallet : profileData.wallet,
                highestDailyScore: highestDailyScore,
                totalGames: totalGames,
                correctGuessNo: correctGuessNo,
                accuracy: accuracy
            })
        }

        fillProfileData()


        //Get username
        async function getName(){
            let response = await api.getName(account)
            let {name} = response
            setName(name)
        }
        getName()

    }, [account, profileData.wallet]);

    return (
        <div>
            <Meta title={pageTitle} />
            <div style={{ backgroundColor: "#655561" }}>
                <div className="profile-page-mainbox" >
                    <div className="profile-page-upper-part">
                        <div className="profile-box design-corner-box">
                            <h1 className="design-corner-box-child">PROFILE PAGE</h1>
                        </div>
                        <div className="username-box design-corner-box">
                            <div className="profile-image-box design-corner-box-child">
                                <img src={expartLogo3} alt={""} />
                                <p>{name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="profile-page-lower-part">
                        <div className="profile-page-completed-games design-corner-box">
                            <div className="design-corner-box-child" style={{ height: "99%" }}>
                                <div className="profile-lower-header">
                                    <h2>COMPLETED GAMES</h2>
                                    <img src={rightArr} alt={""} />
                                </div>
                                <div className="profile-score-table">
                                    <div className="profile-score-table-header">
                                        <span>DATE</span>
                                        <span>ACCURACY</span>
                                        <span>SCORE</span>
                                        <span>EXPART BUCK</span>
                                    </div>
                                    <div className="profile-score-table-data">
                                        {
                                            profileData ?
                                                Object.keys(profileData.dates).map(function(key) {
                                                    // console.log(key, profileData.dates[key]);
                                                    return <DataAccordion 
                                                        key={key} 
                                                        accData={profileData.dates[key]}  
                                                        date={key.substring(0,10)}/>
                                                })
                                               
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-page-stats design-corner-box">
                            <div className="design-corner-box-child" style={{ height: "99%" }}>
                                <div className="profile-lower-header">
                                    <h2>STATS</h2>
                                    <img src={rightArr} alt={""} />
                                </div>
                                <div className="profile-stats-box">
                                    <div style={{ borderBottom: "5px solid #AB8F5E", height: "2px" }}></div>
                                    <br />
                                    <h5>EXPARTBUCK AMOUNT: ${profileData.wallet['c']}</h5>
                                    <p>(DATE JOINED/ACCOUNT MADE)</p>
                                    <p className="text-center" style={{ borderBottom: "3px solid #3D2B0D" }}>STATS</p>
                                    <div></div>
                                    <p>HIGHEST DAILY SCORE: {profileData.highestDailyScore}</p>
                                    <p>TOTAL GAMES PLAYED: {profileData.totalGames}</p>
                                    <p>TOTAL CORRECT GUESSES: {profileData.correctGuessNo}</p>
                                    <p>PERCENTAGE OF GUESS RATE: {profileData.accuracy}%</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile
