import Meta from '../../components/Meta'
import { Container } from 'react-bootstrap'

const About = () => {
  // page content
  const pageTitle = 'About'

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="text-page-top-margin">
        <Container><h1 className="text-center mb-4">ABOUT EXPART</h1>
          <p>Non-fungible Token (NFT) is a unique and non-interchangeable unit of data like digital stored on a digital ledger (blockchain). NFTs can be any digital asset.</p>

          <p>We here at <strong>EXPART</strong> aren't here to tell you why one NFT is better than the other. We can't tell you why a brand new $3 million dollar 2021 Lamborghini Sian Roadster is <em>better</em> or  worth $867 million dollar less than the Mona Lisa.</p>

          <p>That's your job, we let you, the real EXPARTs decide that ;)
          </p>

          <p>Art is subjective, beauty lies in the eye of the beholders. Our mission is to let users like yourself explore NFTs and may be find their own Lamborghini's or Mona Lisa's out there.</p>

        </Container>
      </div>
    </div>
  )
}

export default About