import Typewriter from 'typewriter-effect'
import { useHistory } from "react-router"
import Meta from '../../components/Meta'
import "./EnterExpart.css"

import expartEnterBackground from '../../assets/enter-background.png'

const EnterExpart = ({ pause }) => {
  // page content
  const pageTitle = 'Enter Expart'
  pause()

  const history = useHistory()

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="enter-expart-mainbox">
        <img className="expart-enter-background" src={expartEnterBackground} alt={""} />
        <div className="typewriter-effect-box">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString('EXPART!')
                .pauseFor(200)
                .typeString('<br/>HOW WELL DO YOU KNOW ART?')
                .start()
            }}
          />
        </div>

        <button type="button" className="next nes-btn is-success is-large next-button button-new-styles" onClick={
          () => {
            history.push("/start");
          }
        }>NEXT</button>
        <button type="button" className="rules nes-btn is-success is-large next-button button-new-styles" onClick={
          () => { history.push("/rules"); }
        }>GAME RULES</button>
      </div>
    </div>
  )
}

export default EnterExpart