import { LinkContainer } from 'react-router-bootstrap'
import { useEffect, useState } from 'react'
import Meta from '../../components/Meta'
import './Score.css'

import discordIcon from '../../assets/discord.png'

import RankPic from '../../components/RankPic/RankPic'
import * as api from '../../api/index'

const Result = ({ account }) => {

  const [username, setUsername] = useState()
  const [calledOnce, setCalledOnce] = useState(0)
  const [saving, setSaving] = useState()

  useEffect(() => {
    async function getName() {
      if (calledOnce > 0 || !account) {
        return;
      }
      setCalledOnce(1)
      let response = await api.getName(account)
      let { name } = response
      setUsername(name)
    }
    getName()
  }, [calledOnce, account])
  async function setName(e) {
    e.preventDefault()
    let name = e.target.form.name.value
    if(name !== ""){
      setSaving(true)
      let response = await api.setName(account, name).then(  // eslint-disable-line no-unused-vars
        setUsername(name)
      )
    }
  }


  // page content
  const pageTitle = 'Score'
  const score = localStorage.getItem("game-score");
  // TODO call score update api here (For now node scheduler is updating hourly...)
  //  https://us-central1-brother-nft.cloudfunctions.net/expart-me-scores?f=u&i=a
  return (
    <div>
      <Meta title={pageTitle} />
      <div className="result-score-page">
        <div className="result-score-main">
          <h1 className="score-at-top">SCORE: {score ? score : "0"}</h1>
          <div className="raffle-container">
            <div className="raffle-texts">
              <p style={{ color: "#51250E" }}>YOU HAVE WON {score} EXPART BUCKS!</p>
              {
                (account === null || username === undefined) ?
                  (
                    <>

                      <form>
                        {
                          !saving &&
                          <div>
                            <p>LOG IN TO ENTER THE GIVEAWAY</p>
                            <p>TYPE YOUR USERNAME</p>
                            <input maxLength='8' type="text" name="name" placeholder="NAME TO BE DISPLAYED TO ALL" />
                            <button id="save-name" onClick={setName}>SAVE</button>
                          </div>
                        }
                      </form>
                    </>
                  ) :
                  (<p className='score-box-username'>User: {username}</p>)
              }

            </div>
            <div className="raffle-image">
              <RankPic score={score} />
            </div>
          </div>
          <div className="share-box">
            <span style={{ color: "#51250E" }}>SHARE:</span>
            <a href="https://www.facebook.com/expartdotme" target="_blank" rel="noreferrer"><i className="nes-icon facebook is-medium"></i></a>
            <a href="https://twitter.com/expartme" target="_blank" rel="noreferrer"><i className="nes-icon twitter is-medium"></i></a>
            <a href="https://www.instagram.com/expart.me/" target="_blank" rel="noreferrer"><i className="nes-icon instagram is-medium"></i></a>
            <a href="https://discord.gg/sxenKDZvAV" target="_blank" rel="noreferrer">
              <img src={discordIcon} alt={""} style={{ width: "32px", display: "inlineBlock", paddingBottom: "2px" }} />
            </a>

          </div>
          <div style={{ textAlign: "center" }}>
            <LinkContainer className="next" to='/rankings'>
              <button className="nes-btn is-success is-large">NEXT</button>
            </LinkContainer>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Result