import { useEffect, useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table } from 'react-bootstrap'
import Meta from '../../components/Meta'

import './Rankings.css'
import * as api from '../../api/index'
import RankUp from '../../components/RankUp/RankUp'

import RankPicScroll from '../../components/RankPic/RankPicScroll'

const Rankings = ({ account }) => {
  // page content
  const pageTitle = 'Rankings'
  const score = localStorage.getItem("game-score") ? localStorage.getItem("game-score") : 0
  const [scoreData, setScoreData] = useState(null)
  const [rankUpShown, setRankUpShown] = useState(true)

  let accountToCheck = account ? account.slice(0, 5) + '...' + account.slice(-4) : ''

  const tempUserId = Date.now().toString()
  const [username, setUsername] = useState(
        (account) ? accountToCheck : tempUserId
  )
  useEffect(()=>{
    let nameForUser = (account) ? accountToCheck : tempUserId
    setUsername(nameForUser)
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
   
    function objToList(obj){
      let items = Object.keys(obj).length
      let list = []
      for (var i=0;i<items;i++){
        list.push(obj[i])
      }
      return list
    }
    
    async function getScoreData() {
      let response = await api.getScores()
      let scores={}
      response = JSON.parse(response.data.replace(/'/g, '"'))
      scores['t']=objToList(response['lasthour'])
      scores['a']=objToList(response['alltime'])

      setScoreData(scores)
    }

    async function updateScoreData() { 
      let response = await api.updateScores() // eslint-disable-line no-unused-vars
    }
    updateScoreData()
    getScoreData()
  }, []);

  const closeRankUpPopup = () => {
    async function setScoreData() {
      let response = await api.setScores(account) // eslint-disable-line no-unused-vars
    } 
    if(account){
      setScoreData()
    }
    setRankUpShown(false)
  }

  return (
    <div>
      <Meta title={pageTitle} />

      <div style={{ backgroundColor: "#655561", position: "relative" }}>
        <div className="highscore-screen-mainbox">
          <div className="highscore-innerbox">
            <div className="highscore-innerboxes">
              <span className="highscore-time-frame">Todays</span> <br />
              <span className="highscore-heading">High Scores</span>
              <div className="highscore-table">
                <Table style={{ border: "none" }}>
                  {/* <thead>
                <tr>
                  <th>Rank</th>
                  <th>Name</th>
                  <th>Scores</th>
                </tr>
              </thead> */}
                  <tbody>
                    {
                      scoreData ?
                        scoreData.t.map((rowdata, index) => {

                          return <tr key={index} className={(accountToCheck === rowdata.username) ? 'table-row-red' : ''}>
                            <td>{index + 1}.</td>
                            <td>{rowdata.date}</td>
                            <td>{rowdata.username}</td>
                            <td>{rowdata.score}</td>
                          </tr>
                        }) :
                        null
                    }
                    <tr className='table-row-red'>
                            <td>{scoreData ? scoreData.t.length + 1 : ""}.</td>
                            <td>Now</td>
                            <td>{username}</td>
                            <td>{score}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="highscore-innerboxes">
              <span className="highscore-time-frame">All Time</span> <br />
              <span className="highscore-heading">High Scores</span>
              <div className="highscore-table">
                <Table style={{ border: "none" }}>
                  {/* <thead>
                <tr>
                  <th>Rank</th>
                  <th>Name</th>
                  <th>Scores</th>
                </tr>
              </thead> */}
                  <tbody>
                    {
                      scoreData ?
                        scoreData.a.map((rowdata, index) => {
                          return <tr key={index} className={(accountToCheck === rowdata.username) ? 'table-row-red' : ''}>
                            <td>{index + 1}.</td>
                            <td>{rowdata.date}</td>
                            <td>{rowdata.username}</td>
                            <td>{rowdata.score}</td>
                          </tr>
                        }) :
                        null
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="highscore-your-scores-section">
            <span>Your Score:</span>
            <span style={{ color: "#22B14C" }}>{score}</span>

            <br /><br />
            <div className="rank-image-box-container">
              <span>Your Rank:</span>
              {/* <img src={pebbles} style={{ border: "6px solid white" }} alt={""} /> */}
              <RankPicScroll score={score} />
            </div>
          </div>
          <br />

          <div style={{ textAlign: "center", padding: "30px" }}>
            <LinkContainer className="next" to='/'>
              <button className="nes-btn is-success is-large">NEXT</button>
            </LinkContainer>
          </div>
        </div>
        {
          rankUpShown && <RankUp score={score} closeRankUpPopup={closeRankUpPopup} />
        }
      </div>
    </div>
  )
}

export default Rankings