import { useState } from 'react'
import { Tab, Tabs, Container } from 'react-bootstrap'
import Meta from '../../components/Meta'

import './PrivacyToS.css'

const PrivacyTOS = () => {
    const [key, setKey] = useState('privacy');
    // page content
    const pageTitle = (key === "privacy") ? 'Privacy Policy' : 'Terms of Service'

    return (
        <div className="text-page-top-margin privacy-tos-page">
            <Meta title={pageTitle} />
            <Container>
                <Tabs
                    id="controlled-tab-example"
                    transition={false}
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="privacy" title="Privacy Policy">
                        <h1> EXPART Privacy Policy</h1>
                        <br />
                        <p>November 1 2021</p>
                        <p>This Privacy Policy explains how EXPART collects, uses, shares, and protects user information obtained through the EXPART.me website. The terms “we,” “us,” and “our” refer to EXPART and its affiliates. When we ask for certain personal information from users it is because we are required by law to collect this information or it is relevant for specified purposes. Any non-required information you provide to us is done so voluntarily. You decided whether to provide us with these non-required information; you may not be able to access or utilize all of our Services if you choose not to.</p>
                        <p>
                            By using the Site, you consent to the data practices described in this Privacy Policy. On occasion, EXPART may revise this Privacy Policy to reflect changes in law or our personal data collection and use practices. If material changes are made to this Privacy Policy, the changes will be announced by posting on the site. We will ask for your consent before using your information for any purpose that is not covered in this Privacy Policy.
                        </p>
                        <p>
                            The latest privacy policy has incorporated elements from the General Data Protection Regulation (GDPR) as we act in accordance to its personal information processing rules within the European Economic Area (EEA). The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at [Terms of Service], unless otherwise defined in this Privacy Policy.
                        </p>
                        <br /><br />
                        <h2>What kinds of information do we collect?</h2>
                        <p>
                            We want you to understand the types of information we collect when you register for and use EXPART’s services. For METAMASK, those terms are available at <a href="https://metamask.io/terms.html">https://metamask.io/terms.html</a> and <a href="https://metamask.io/privacy.html">https://metamask.io/privacy.html</a>
                        </p>
                        <br />
                        <h3>Service Usage Information</h3>
                        <p>Through your use of the EXPART platform, we also can monitor and collect tracking information related to usages such as access date & time, device identification, operating system, browser type, and IP address. This information may be directly obtained by EXPART or through third party services. This service usage data helps our systems to ensure that our interface is accessible for users across all platforms and can aid during criminal investigations.</p>
                        <br /><br />
                        <h2>Why does EXPART collect this information</h2>
                        <h3>To provide and maintain our services</h3>
                        <p>We use the information collected to deliver our services and verify user identity.
                        </p>
                        <p>We use the IP address and unique identifiers stored in your device’s cookies to help us authenticate your identity and provide our service. Given our legal obligations and system requirements, we cannot provide you with services without data like identification, contact information, and transaction-related information.
                        </p>
                        <h3>To protect our users</h3>
                        <p>We use the information collected to protect our platform, users’ accounts, and archives.</p>
                        <p>We use IP addresses and cookie data to protect against automated abuse such as spam, phishing and Distributed Denial of Service (DDoS) attacks.
                        </p>
                        <h3>To comply with legal and regulatory requirements</h3>
                        <p>Respect for the privacy and security of data you store with EXPART informs our approach to complying with regulations, governmental requests and user-generated inquiries. We will not disclose or provide any personal information to third party sources without review from our legal case team and/or prior consent from the user.</p>
                        <h3>To measure site performance</h3>
                        <p>We actively measure and analyze data to understand how our services are used. This review activity is conducted by our operations team to continually improve our platform’s performance and to resolve issues with the user experience.</p>
                        <p>We continuously monitor our systems’ activity information and communications with users to look for and quickly fix problems.</p>
                        <h3>To communicate with you</h3>
                        <p>We use personal information collected, like an email address to interact with users directly when providing customer support on a ticket or to keep you informed on logins, transactions, and security. Without processing your personal information for confirming each communication, we will not be able to respond to your submitted requests, questions and inquiries. All direct communications are kept confidential and reviewed internally for accuracy.</p>
                        <br /><br />
                        <h2>How does EXPART protect user data</h2>
                        <p>EXPART has implemented a number of security measures to ensure that your information is not lost, abused, or altered. Our data security measures include, but are not limited to: PCI Scanning, Secured Sockets Layered encryption technology, pseudonymization, internal data access restrictions, and strict physical access controls to buildings & files. Please note that it is impossible to guarantee 100% secure transmission of data over the Internet nor method of electronic storage. As such, we request that you understand the responsibility to independently take safety precautions to protect your own personal information.</p>
                        <p>If you suspect that your personal information has been compromised, especially account and/or password information, please contact EXPART customer service immediately.</p>
                        <br /><br />
                        <h2>How to request data deletion?</h2>
                        <p>To delete your account with all the collected data, please, make a request to EXPART support team by writing an email to bus.expart@gmail.com. Please, add the letter subject "Data deletion request" The data will be deleted within 7 working days, from the request confirmation.</p>
                        <br /><br />
                        <h2>Contact us</h2>
                        <p>We are committed to respecting the freedoms and rights of all our EXPART users who have placed their trust in our service. If you should have any questions or concerns regarding this Privacy Policy, or if you would like to file a Data Protection request, please contact us by bus.expart@gmail.com.</p>
                        <br /><br />
                        <h2>End of Agreement</h2>
                        <br />
                        <h2 className="text-center">Privacy Policy
                        </h2>
                        <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, <a href="https://expart.me">https://expart.me</a> website.</p>
                        <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                        <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Terms of Service, unless otherwise defined in this Privacy Policy.</p>
                        <br />
                        <h4>Information Collection and Use</h4>
                        <p>‍For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you. ‍</p>
                        <br />
                        <h4>Log Data</h4>
                        <p>‍We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol ("IP") address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics. ‍</p>
                        <br />
                        <h4>Cookies</h4>
                        <p>‍Cookies are files with a small amount of data that is commonly used as an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive. Our website uses these "cookies" to collect information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service. For more general information on cookies, please read "What Are Cookies". ‍</p>
                        <br />
                        <h4>Service Providers</h4>
                        <p>‍We may employ third-party companies and individuals due to the following reasons:
                        </p>
                        <ul>
                            <li>To facilitate our Service;</li>
                            <li>To provide the Service on our behalf;</li>
                            <li>To perform Service-related services; or</li>
                            <li>To assist us in analyzing how our Service is used.</li>
                        </ul>
                        <p>We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose. ‍

                        </p>
                        <br />
                        <h4>Security</h4>
                        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security. ‍</p>
                        <br />
                        <h4>Links to Other Sites</h4>
                        <p>‍Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                        <br />
                        <h4>Children's Privacy</h4>
                        <p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
                        <br />
                        <h4>Code of Conduct</h4>
                        <p>In the interest of fostering an open and welcoming environment, we as contributors and maintainers pledge to making participation in our project and our community a harassment-free experience for everyone, regardless of age, body size, disability, ethnicity, sex characteristics, gender identity and expression, level of experience, education, socio-economic status, nationality, personal appearance, race, religion, or sexual identity and orientation. </p>
                        <br />
                        <h4>Changes to This Privacy Policy</h4>
                        <p>‍We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>
                        <br />
                        <h4>‍Contact Us</h4>
                        <p>‍If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
                    </Tab>
                    <Tab eventKey="tos" title="Terms of Service">
                        <h1>EXPART Terms of Service</h1>
                        <br />
                        <p>These are the terms and conditions that define the rules and regulations to use the <a href="https://expart.me">EXPART</a> website.</p>
                        <p>This website is the property of EXPART, developed by the EXPART development team. For METAMASK, those terms are available at <a href="https://metamask.io/terms.html">https://metamask.io/terms.html</a> and <a href=" https://metamask.io/privacy.html"> https://metamask.io/privacy.html</a></p>
                        <br /><br />
                        <h2>Acceptance of the terms</h2>
                        <p>You must be of legal age to abide by the agreement in order to accept the terms. By accessing this website we assume that you hereby accept the terms and conditions of this website. If you do not agree to the General Terms and conditions, do not continue to use any of our services of EXPART.me. You may accept the Terms or abide by the general agreement. EXPART referred collectively to “We” or “Us” or” Ourselves” or “Our” We refer to users of the website as “You” or “Users” or “Client”. You hereby bound to these terms. Any of the above terminologies in singular plural or he or she are interchangeable and they refer to the above terminologies.</p>
                        <br /><br />
                        <h2>General terms</h2>
                        <br />
                        <h4>Severability</h4>
                        <p>Each paragraph of these terms and conditions has separate functions. If any authority or court decides that any of the terms are against the law, the remaining part of the terms and conditions remain the same in full force and effect.</p>
                        <br />
                        <h4>Notification</h4>
                        <p>We may send notice to you by email or post regarding the service and the website or it may be sent by any other mode. Such notices will be considered to be effective from the time of delivery or dispatch. If you send the notice, it will be considered effective when it is received by us.</p>
                        <br />
                        <h4>Modification of terms</h4>
                        <p>Respect for the privacy and security of data you store with EXPART informs our approach to complying with We may or may not change the terms and conditions from time to time. You acknowledge and agree to a modification of terms. If you do not agree to the modification of terms, kindly do not access or continue to use our service. Kindly check all the updates on this page periodically. We comply with the applicable legal agreements to provide or send you the notice of updates or changes to these terms and conditions. We assume your continued use as your acceptance of the changes in the terms and conditions.</p>
                        <p>We will not disclose or provide any personal information to third-party sources without review from our legal case team and/or prior consent from the user.</p>
                        <br /><br />
                        <h2>Cookies</h2>
                        <p>We make use of cookies and by accessing EXPART.me, we assume that you agreed to make use of the cookies with the EXPART privacy policy.</p>
                        <p>Most of the websites use cookies to collect user details in a temporary file to easily access the website for the next visit. It is implemented to make users comfortable using our website and to enable the proper functioning of certain areas.</p>
                        <br /><br />
                        <h2>License</h2>
                        <p>As a user of this website, you are allowed to have non-exclusive, non-transferable, limited access to use the website.</p>
                        <p>EXPART possesses the right to cancel the license at any time for any valid reason. You may access this website with the following restrictions:</p>
                        <ul>
                            <li>You may use the website on your own computer for your personal and non-commercial use.</li>
                            <li>You are not allowed to republish any of the content, any part of the website, service/contest in any form</li>
                            <li>Not allowed to copy, sell, sub-license, or rent any part of the EXPART.</li>
                            <li>You must not redistribute the content.</li>
                            <li>You are not allowed to use any device, software to interfere or interrupt or attempt to hinder the proper workflow or functionality of the website.</li>
                        </ul>
                        <p>Cyptosoftwares offers the opportunity to exchange your views in a certain part of the websites. Comments posted on this website reflect the views and opinions of the person who posted the comments and in no way, it reflects the views and opinions of EXPART. EXPART may not be responsible or liable for the comments posted on the website or not responsible for any liability, damage, or expense caused as a result of any comment on the website.</p>
                        <p>EXPART reserves all the rights to monitor the website and removes any unwanted comments which are considered to be inappropriate, offensive, or violate any rules or regulations of these terms and conditions of EXPART.</p>
                        <br /><br />
                        <h2>Content service and ownership</h2>
                        <p>The website may contain the content created by the EXPART or any of our users. The content, graphics, compilation, digital conversation, design, contests, services, and all intellectual property rights including logos, names, related names, and symbols (referred to as “Intellectual Property”) are protected under copyright, trademark law, industrial property rights. Copyright infringement is strictly prohibited.</p>
                        <p>If any of the above terms are not followed, accepted violates the terms and conditions as per the copyright law, trademark law and you abide by the legal liability for the same.</p>
                        <br /><br />

                        <h2>Hyperlinking to the website</h2>
                        <p>Below organization may hold the rights to link our website without any prior approval.</p>
                        <h4>Government agencies</h4>
                        <p>Unless authorized by EXPART, no one shall hyperlink the website or any part of the website (which may include the following parts but not limited to: logos, content, copyright material, branding) to their own website. Framing the website/ referencing the URL (Uniform Resource Locator) without any written permission or prior approval is strictly prohibited.</p>
                        <p>We may approve the link request of the organization if we find that the following conditions are met:</p>
                        <ul>
                            <li>The link should not look unfavorable to us or any of our accredited business</li>
                            <li>The organization should not have a negative impact on our business</li>
                            <li>The benefit to us with respect to the hyperlink</li>
                            <li>The presence of the link in the general information</li>
                        </ul>
                        <br /><br />
                        <h2>Iframes</h2>
                        <p>You are not allowed to create any frames that affect or alter our visual representation or design or the appearance of our website without any prior written permission or approval</p>
                        <h2>Content liability</h2>
                        <p>We shall not be liable for any content that appears on your website. You hereby acknowledge and agree to protect us from all claims that may arise on your website. No link should be allowed on any website that may be considered as criminal, or copyright-infringing, or violating the terms and conditions of third party rights.</p>
                        <p>You specifically need to agree or cooperate with the website to cease any such activities against hyperlinking. You also acknowledge that you shall be liable for any such legal liabilities, damages, expenses associated therewith.</p>
                        <br /><br />
                        <h2>Rights reserved</h2>
                        <p>We reserve the right to remove any or all the links to our website. You accept to remove immediately as it is requested. We may change, amend the terms and conditions related to hyperlinking policy. We assume that by continuously linking to our website, you accept or agree to the updated terms and conditions of the hyperlinking policy</p>
                        <br /><br />
                        <h2>Link removal request from our website</h2>
                        <p>If you find any link is not appropriate or offensive, you are allowed to inform or contact us for the removal request. We will consider your request and shall proceed only after examining the link and we are not obligated to respond to you immediately or directly</p>
                        <br /><br />
                        <h2>Disclaimer</h2>
                        <p>As per the applicable law, we exclude all conditions, warranties, representations relating to our website to the maximum extent.</p>
                        <p>The following will not:</p>
                        <ul>
                            <li>Exclude or avoid or limit your or our liabilities for death or any personal injury that occurred</li>
                            <li>Exclude or avoid or limit your or our liabilities for fraudulent activities</li>
                            <li>Exclude or avoid or limit your or our liabilities that may or may not fall under applicable law</li>
                        </ul>
                        <br /><br />
                        <h2>Disclaimer for downloads</h2>
                        <p>Users or Visitors download any information at their own risk. EXPART not liable or no warranty for such downloads are free from viruses, worms, including but not limited to corrupting computer codes As far as the website, services, information are provided to the users for free of cost, we will not be responsible for any natural damage or loss.</p>
                        <h2>End of Agreement</h2>
                    </Tab>
                </Tabs>
            </Container>
        </div>
    )
}

export default PrivacyTOS