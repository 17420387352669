const MobileWarning = ({ mobileWarningOpen, setMobileWarningOpen }) => {

    return (
        <>
        <div style={{
                textAlign: "center",
                position: "absolute",
                transform: "translate(-50%, 0)",
                left: "50%",
                top: "100px",
                width: "100%"
            }}>
            {
                mobileWarningOpen &&
                <div className="metamask-unavailability-mobile-dialog">
                    <p className="mobile-metamask-dialog-title">Metamask Unavailable for Mobile Device</p>
                    <p>Please use PC Browser with Metamask to save your progress.</p>
                    <button className="nes-btn" onClick={() => { setMobileWarningOpen(false) }}>Okay</button>
                </div>
            }
        </div>
            {/* <dialog className="nes-dialog" id="dialog-default" open={mobileWarningOpen}
            style={{
                textAlign: "center",
                position: "absolute",
                transform: "translate(-50%, 0)",
                left: "50%",
                top: "100px"
            }}
        >
            <form method="dialog">
                <p className="title">Metamask Unavaialable for Mobile Device.</p>
                <p>Please use PC Browser with Metamask to save your progress.</p>
                <menu className="dialog-menu">
                    <button className="nes-btn" onClick={() => { setMobileWarningOpen(false) }}>Okay</button>
                </menu>
            </form>
        </dialog> */}
        </>
    )
}

export default MobileWarning