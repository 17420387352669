import Meta from '../components/Meta'
import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap'
import Card from "react-bootstrap/Card";
import React, { useState } from "react";
import { MDBRipple } from 'mdb-react-ui-kit';
import Parser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'


//7 Levels
//Speed and correctness are measured
const tutorial = {
  1: {
    l: {
      i: 'https://ipfs.infura.io/ipfs/QmcAD3T5jNaPVwNmvf1YarjzKr6434BsiWMQyZ7ij8JdaM',
      u: 225000000,
      d: `The One Bel Air is a 105,000-square-foot megamansion said to be the biggest modern home in the US. The home sits atop a hill in Bell Air, with views of the Los Angeles basin. The colossal home features 20 bedrooms, including eight bedrooms for the staff and a three-bedroom guest house, approximately 6 elevators, a library, cigar room and candy room. The home is promoted as having seven pools, a 50-car garage, a 10,000-bottle wine cellar, a four-lane bowling alley, a 50-seat movie theater, a putting green, wellness center and gym, beauty salon, juice bar, tennis court and even it's own nightclub.`
    },
    r: {
      i: 'https://ipfs.infura.io/ipfs/QmVusWXFrwVDTQNDdYrqxNdUbVQUh5SLtc9v99MXR5jh9U',
      u: 8000000,
      d: `This lovely home set in Toronto spans at nearly 10,000 square feet, this opulent five-bedroom home has a bevy of luxurious features, including a movie theatre, elevator, aquarium, solarium and billiards room. It overlooks a ravine and has a “zen-inspired” design, according to the broker, with organic finishes and a natural colour scheme. Superhero fans take note: this place will be featured in the upcoming Netflix series Jupiter’s Legacy.`
    },
    t: {
      r: `Right on!`,
      w: `Come on`,
      l: `Which one cost more?`
    },
    s: 'l'
  },
  2: {
    l: {
      i: 'https://ipfs.infura.io/ipfs/QmU3ykJuekYA8T8rm2km715YbweU5ziXEkZmV2zCERZue1',
      u: 870000000,
      d: `The Mona Lisa is a half-length portrait painting by Italian artist Leonardo da Vinci in 1503. Considered an archetypal masterpiece of the Italian Renaissance, it has been described as "the best known, the most visited, the most written about, the most sung about, the most parodied work of art in the world". The Mona Lisa is one of the most valuable paintings in the world. It holds the Guinness World Record for the highest known insurance valuation in history at US$100 million in 1962 (equivalent to $870 million in 2021).`
    },
    r: {
      i: 'https://ipfs.infura.io/ipfs/QmUhdKqceorXnHHnVBPSG5tmiT8bCCGgD4szgEKeYS7rSb',
      u: 4800000000,
      d: `The most expensive yacht around $4.8 billion is History Supreme. This magnificent accomplishment is built from 10,000 kilograms of solid gold and platinum and is 100-foot long. It was designed by Stuart Huges, a world-renowned luxury designer, for Malaysia’s richest man, Robert Knok. The History Supreme took roughly three years to build and features an array of luxury items.The master bedroom includes a genuine Tyrannosaurus Rex bone statue and a wall made entirely of meteorite rocks! If that wasn’t enough, the master suite also has a 24-carat gold Aquavista Panoramic Wall Aquarium. `
    },
    t: {
      r: `Gold! Let's keep going...`,
      w: `That one was tricky`,
      l: `Let's try something a little harder shall we?
        <br>
        Which one is valued higher?`
    },
    s: 'r'
  },
  3: {
    l: {
      i: 'https://ipfs.infura.io/ipfs/QmPcWkDBWt8sSRVtmiXKEg1ahFwFnrRazqY62HN1iwEcgx',
      u: 50,
      d: `This beautiful <b>Colorful Rain Print by DVQ ART</b> is one of the many high definition art pieces you can check out on Amazon. Printed on canvas with vivid color on thick high quality canvas to create the look and feel of the original nature and masterpiece. Canvas Wall Art and Canvas Paintings are the modern way to brighten the walls of your home, and relax you after work. It is sure to captivate wherever it is hung.
        <br><br>
       Features: Rain Graffiti street artwork, Model number B1002_6, ASIN B01HO9YQGS.`,
      b: {
        l: `https://www.amazon.com/DVQ-ART-Colorful-Paintings-Decorative/dp/B01HO9YQGS/ref=sr_1_6?crid=13QN2B9GPS4F2&dchild=1&keywords=canvas+art&qid=1635154733&sprefix=canvas+art%2Caps%2C78&sr=8-6`,
        t: 'Buy now'
      }
    },
    r: {
      i: 'https://bafybeia5jrlzpwcborennizethptvsbntttpa2mmnrxekplb3ifujxweo4.ipfs.infura-ipfs.io/',
      u: 3800000,
      d: `<b>White Fire I</b>, a luminous painting by Barnett Newman from 1954. This extremely rare painting by Newman is characteristic of his pure style and mystical content. Robert Rauchenberg is represented by a combine painting of 1960, Nettle.
        <br></br>
        White Fire I by Barnett Newman was purchased for $3.8 million. “The name” White Fire “- a mystical term originating from the Torah. As such, it is definitely filled with deep spiritual feeling, which Newman tried to give the viewers of his paintings.`
    },
    t: {
      r: `Interesting choice... you might be onto something.`,
      w: `I personally choose this one as well!`,
      l: `How about some fine wall canvases to help decorate the room? Which one <i>speaks</i> to you more?`
    },
    s: 'r'
  },
  4: {
    l: {
      i: 'https://ipfs.infura.io/ipfs/Qme44vV54bQe3nz8XskY7kYGd3wmxvQmEuWLA54g9KTXR5',
      u: 1650000,
      d: `<b>Green White</b> by Ellsworth Kelly
        <br><br>
       Once part of the Robert and Jean Shoenberg collection, this 1961 artwork came into the market at Christie’s 2008 sale. Kelly was a camouflage artist during his stint in the army in the 1940s. He was a part of the unit known as ‘the Ghost army’ comprising artists and designers who painted objects that would misdirect enemy soldiers.`
    },
    r: {
      i: 'https://ipfs.infura.io/ipfs/QmTzrsM6YWBWYkYPrDgDqL7Yrm2c7vwXrpRPqYiAh7G7kv',
      u: 120000,
      d: `An art piece of a banana duct-taped to a wall by performance artist Maurizio Cattelan - Comedian, “a symbol of global trade, a double entendre, as well as a classic device for humor”.
        <br><br>
        The controversy-stirring artwork first sold for $120,000, and then was sold two more times for $120,000 to $150,000
        <br><br> 
        Banana Artwork is now in the Guggenheim’s Collection.`
    },
    t: {
      r: `That person was clearly starving that day`,
      w: `I could draw that on Microsoft Paint`,
      l: `Which item in 2019 was sold for US$120,000 at Art Basel Miami Beach Museum?`
    },
    s: 'r'
  },
  5: {
    l: {
      i: 'https://ipfs.infura.io/ipfs/Qma2sDRbuEPkjDMMreRiPjaWcFXivvVPMFKFfPV61rcqt9',
      u: 12.90,
      d: `Amazon Essentials Men's 2-Pack Regular-Fit Short-Sleeve Stripe V-Neck T-Shirts
        <br><br>
       An Amazon Brand - Two-pack of regular-fit tees featuring stripes and a V-neck
       `,
      b: {
        l: "https://www.amazon.com/Amazon-Essentials-Loose-Fit-Short-Sleeve-T-Shirts/dp/B07HH7VXVP/ref=sr_1_2?dchild=1&keywords=Men%27s+Striped+T+Shirts&qid=1635156292&sr=8-2",
        t: 'Buy Now'
      },
    },
    r: {
      i: 'https://ipfs.infura.io/ipfs/QmdbsP5AtGiwDgTVqc5KS9DjDwBDUSVFCvDEUmJHxXuRTC',
      u: 500,
      d: `SHORT-SLEEVED NAVY STRIPED T-SHIRT WITH BOAT NECKLINE AND SAINT LAURENT LOGO EMBROIDERY ON THE CHEST.`,
      b: {
        l: "https://www.ysl.com/en-ca/t-shirts-and-sweatshirts/saint-laurent-striped-t-shirt-662011YB2PU9390.html",
        t: 'Buy Now'
      },
    },
    t: {
      r: `Free shipping when you spend $150.`,
      w: `Would you look at that, two shirts for the price of one!`,
      l: `Which article of clothing is priced higher?`
    },

    s: 'r'
  },
  6: {
    l: {
      i: 'https://ipfs.infura.io/ipfs/QmNm2utuNDDCPJbr5pLEPoPFFsmjGdzgJByWMfCRA7n5TC',
      u: 1785,
      d: `Global Offensive is a multiplayer first-person shooter developed by Valve. In-game features several skins, also referred to as finishes that allow weapons shown with different textures. They can be equipped in game, entirely cosmetic holding no gameplay function.
        <br><br>
       This skin in particular known as StatTrak <b>Huntsman Knife Damascus Steel</b> features realistic looking steel with intricate ripples.
        <br><br>`,
      b: {
        l: 'https://www.dbltap.com/posts/6014547-6-most-expensive-knife-skins-in-cs-go',
        t: 'learn more'
      }
    },
    r: {
      i: 'https://ipfs.infura.io/ipfs/QmWTwsSyvQjESG8v4vMVjET2A8BZ8YL9QxdTXT2PUV2LBC',
      u: 45.99,
      d: `<b>Professional Damascus Chefs Knife, 67-layer Handmade 8" Damascus Chef Knife, Japanese VG10 Super Steel Core</b>
        <br><br>
        High quality 67-layer Damascus steel for precision, beauty, and strength. Handmade, HRC 62 Rockwell hardness, 100% Quality Control.12 Degrees super razor sharp edge, the sharpness can keep more than 6 months. Ergonomically designed rounded PAKKA WOOD Handle to prevent slip and create comfortable holding.The knife come with a black gift box, very suitable as a gift to mom, wife or friends.
        `,
      b: {
        l: 'https://www.amazon.com/ALLWIN-HOUSEWARE-WH-0701A/dp/B077YLPMNX',
        t: 'Buy now'
      }
    },
    t: {
      r: `But when I stab the bad guys this looks wicked sick`,
      w: `Orange slices anyone? Can your digital knife do that HUH?!`,
      l: `Counter-Strike Digital Huntsman Knife Damascus Steel Skin versus One Professional Damascus Chefs Knife, 67-Layer Handmade 8" Damascus Chef Knife`
    },
    s: 'l'
  },
  7: {
    l: {
      i: 'https://ipfs.infura.io/ipfs/QmcwYUdNexddpaWcmqkq1GwnR5xb15oLUwFhVKmQmgcb7S',
      u: 60000,
      d: `<b>One kilogram Solid 24K Gold Bar </b>
        <br><br>
       24K gold is pure, non-alloyed gold. This type of gold only contains gold, without any silver, zinc, nickel, palladium, copper or other metals.
       1 Kilo Gold Bar boasts 32.5 ounces of . 999 pure gold. 
        <br><br>
       The current price for 1KG is USD $57,918.28`
    },
    r: {
      i: 'https://ipfs.infura.io/ipfs/QmaSW3zU5DYhw2c5UGnRgkmaEsvAY88rT5N9CMGpP6TqzE',
      u: 15300000,
      d: `<b>CryptoPunk #7523</b>, One of 9 Alien punks from a set of 10,000 pixel-art characters made by Larva Labs in 2017. This highly sought-after Alien variety with blue-green screen wearing a medical mask auction and sold for 15.3 million by Sotheby's.
        <br><br>
        The work is in the form of an NFT — meaning it is authenticated by blockchain, which certifies its originality and ownershipThe NFT is sent to the buyer's cryptocurrency wallet, and no physical artwork changes hands. It was bought by the Israeli entrepreneur Shalom Meckenzie, who is the largest shareholder of digital sports company DraftKings
        <br><br>`,
      b: {
        l: 'https://www.larvalabs.com/cryptopunks/details/7523',
        t: 'Read more'
      }

    },
    t: {
      r: `Move over boomer, new era is coming.`,
      w: `My gold bar doubles as a paper weight though`,
      l: `I don't play video games, what else you got?
        <br>
        <br>
        Digital Art (NFT) versus 1KG Solid 24K gold bar`
    },
    s: 'r'
  }
}


const Tutorial = () => {
  // page content
  const site = 'expart.me: '
  const pageTitle = 'Tutorial'
  const [showDetails, setShowDetails] = useState(false);
  const [maskRight, setMaskRight] = useState(false);
  const [maskLeft, setMaskLeft] = useState(false);

  const getNumberUnit = function (num) {
    var units = ["Million", "Billion", "Trillion", "Quadrillion", "Quintillion", "Sextillion"]
    var unit = Math.floor((num / 1.0e+1).toFixed(0).toString().length)
    var r = unit % 3
    var x = Math.abs(Number(num)) / Number('1.0e+' + (unit - r)).toFixed(2)
    if (units[Math.floor(unit / 3) - 2]) {
      return x + ' ' + units[Math.floor(unit / 3) - 2]
    } else {
      return num
    }

  }

  const CardBody = (side) => (
    <div>
      <ListGroup className="list-group-flush">
        <ListGroupItem>USD: ${getNumberUnit(side.u)}</ListGroupItem>
      </ListGroup>
      <Card.Body>
        <Card.Text>
          {Parser(side.d)}
        </Card.Text>
      </Card.Body>
      {side.b && <Card.Body>
        <Card.Link href={side.b.l}>{side.b.t}</Card.Link>
      </Card.Body>
      }
    </div>
  )

  const evaluateRound = (guess) => {
    //Todo extend to API calls
    if (level.s === guess) {
      //right
      setRoundResult('r')
    } else {
      //Wrong
      setRoundResult('w')
    }
  }

  const guess = (selection) => {
    setShowDetails(true)
    setProceed(true)
    if (levelId.count === 1) {
      setFirstDone(true)
    }
    if (roundOver) {
      //Prevent clicking other 
      return;
    }
    if (selection.target.className.indexOf('rightItem') >= 0) {
      evaluateRound('r')
      setMaskLeft(true)
      setRoundOver(true)
    } else {
      evaluateRound('l')
      setMaskRight(true)
      setRoundOver(true)
    }
  }
  
  const history = useHistory();
  const moveToNextTutorial = () => {
    //Clear states
    setMaskLeft(false)
    setMaskRight(false)
    setShowDetails(false)
    setRoundOver(false)
    setProceed(false)

    //Advance level
    if (levelId.count < 7) {
      setLevelId({ count: levelId.count++ })
      setLevel(tutorial[levelId.count])
      setRoundOver(false)
      // console.log(levelId)
    }
    else{
      setAction({
        text: 'Play Game',
        fn: () => {
          history.push('/start')
        }
      })
    }
  }

  const [levelId, setLevelId] = useState({ count: 1 });
  const [level, setLevel] = useState(tutorial[levelId.count]);
  const [roundOver, setRoundOver] = useState(false);


  const defaultAction = {
    text: 'Next',
    fn: () => {
      moveToNextTutorial()
    }
  }

  const [action, setAction] = useState(defaultAction);
  const [proceed, setProceed] = useState(false);
  const [firstDone, setFirstDone] = useState(false);
  const [roundResult, setRoundResult] = useState('r');


  return (
    <div className="tutorial">
      <Meta title={site + pageTitle} />
      <Row className="justify-content-md-center">
        <Col md="auto">
          {!roundOver &&
            <h1>{Parser(level.t.l)}</h1>
          }
          {roundOver &&
            <h1>{Parser(level.t[roundResult])}</h1>
          }
        </Col>
      </Row>
      <Row>
        <Col xs={6} className={`${maskLeft ? "maskLeft" : ""}`}>
          <MDBRipple
            className='bg-image hover-overlay shadow-1-strong rounded'
            style={{ maxWidth: '22rem', cursor: 'pointer' }}
            rippleTag='div'
            rippleColor='light'
            onClick={guess}
          >
            <Card style={{ width: '20rem' }}>
              <Card.Img variant="top" src={level.l.i} />
              {showDetails && CardBody(level.l)}
            </Card>
            <div
              className='mask leftItem'
              style={{
                background: 'linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)',
              }}
            ></div>
          </MDBRipple>
        </Col>

        <Col xs={6} className={`${maskRight ? "maskRight" : ""}`}>

          <MDBRipple
            className='bg-image hover-overlay shadow-1-strong rounded'
            style={{ maxWidth: '22rem', cursor: 'pointer' }}
            rippleTag='div'
            rippleColor='light'
            onClick={guess}
          >
            <Card style={{ width: '20rem' }}>
              <Card.Img variant="top" src={level.r.i} />
              {showDetails && CardBody(level.r)}
            </Card>
            <div
              className='mask rightItem'
              style={{
                background: 'linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)',
              }}
            ></div>
          </MDBRipple>
        </Col>
      </Row>
      {
        proceed &&
        <div className="actionButton" onClick={action.fn}>{action.text}</div>
      }
      {
        firstDone &&
        <div className="tutorialLocation">{levelId.count}/7</div>
      }
      {!firstDone &&
          <LinkContainer to='/enter'>
           <button className='btn btn-primary'>Skip Tutorial</button>
         </LinkContainer>
      }

    </div>
  )
}

export default Tutorial