import "./RankUp.css"
import RankPicScroll from '../../components/RankPic/RankPicScroll'

const RankUp = ({ score, closeRankUpPopup }) => {

    return (
        <div className="level-up-popup-container" style={{ height: "100%" }}>
            <div className="level-up-popup-background">
                <h1>YOU HAVE LEVELED UP</h1>
                <br/> <br/>
                <div className="highscore-your-scores-section">
                    <span>Your Score:</span>
                    <span style={{ color: "#22B14C" }}>{score}</span>

                    <br /><br />
                    <div className="rank-image-box-container">
                        <span>Your Rank:</span>
                        <RankPicScroll score={score} />
                    </div>
                </div>
                <br/> <br/>
                <div className="warning-okay-button">
                    <button onClick={closeRankUpPopup} className='nes-btn is-warning'>Okay</button>
                </div>
            </div>
        </div>
    )
}

export default RankUp