import './Shop.css'
export default function SuccessMessage({ message }) {
    if (!message) return null;
  
    return (
      <div className="alert mt-5">
        <div className="flex-1">
          <label>{message}</label>
          <a href="/#game">Continue</a>
        </div>
      </div>
    );
  }
  