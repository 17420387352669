import { useState } from 'react'
import { Accordion } from 'react-bootstrap'

import './DataAccordion.css'

import rightArr from '../../assets/button_matchhistory_up.png'
import downArr from '../../assets/button_matchhistory_down.png'

const DataAccordion = ({ date, accData }) => {

    const [isOpen, setIsOpen] = useState(false)
    const buttonStyle = { padding: "0", border: "none", backgroundColor: "transparent" }
    let [year, monthNumber, day] = date.split('-')
    let month = getMonth(parseInt(monthNumber))

    let {s, r, ...rounds} = accData

    return (
        <>
            <Accordion>
                <div className="score-table-data">
                    <div className="score-table-data-toggler">
                        <div>{month} {day}|{year}</div>
                        <div>{accData.s}|{accData.r}</div>
                        {/* <div>{score[date]}</div> */}
                        <div>{accData.s}</div>
                        <div className="expart-buck-and-toggler">
                            <span>{accData.s}</span>
                            <Accordion.Toggle eventKey="0"
                                style={buttonStyle} onClick={() => { setIsOpen(!isOpen) }}>
                                <img src={isOpen ? rightArr : downArr} alt={""} style={{ width: "40px" }} />
                            </Accordion.Toggle>
                        </div>
                    </div>

                    <Accordion.Collapse eventKey="0">
                        <div className='score-data-inner-table'>
                            <div className="score-data-inner-table-heading">
                                <span>ROUND</span>
                                <span>TIME SPENT</span>
                            </div>
                            {
                                Object.keys(rounds).map(function (key) {
                                    let roundTime = rounds[key].d ? rounds[key].d.toFixed(2) : 0
                                    let leftPrice = rounds[key].l.split('-')[0]
                                    let rightPrice = rounds[key].r.split('-')[0]
                                    return <div className="score-data-inner-table-row" key = {key}>
                                        <div className='score-round-details'>
                                            <span className='score-round-details-roundno'>ROUND {key}</span>
                                            <div className='score-round-details-dollars'>
                                                <span className='dollar-color-box dollar-color-box-pink'></span> <span className='game-score-dollar'>${leftPrice}</span>
                                                <br />
                                                <span className='dollar-color-box dollar-color-box-red'></span> <span className='game-score-dollar'>${rightPrice}</span>
                                            </div>
                                        </div>
                                        <div className='score-round-time'>{roundTime} Seconds</div>
                                    </div>
                                })
                            }
                        </div>
                    </Accordion.Collapse>
                </div>
            </Accordion>
        </>
    )
}

export default DataAccordion

function getMonth(monthNo) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    return months[monthNo - 1]
}
