import "../Warning/Warning.css"

import warningLife from '../../assets/zerolives.png'

const BuyMoreLives = () => {
    const goToStore =()=>{
        window.location.replace('#/shop')
    }

    return (
        <div className="game-warning-container">
            <div className="warning-black-background">
                <h1>
                    You are out of bucks.
                </h1>
                <h4 className="info">Wait until tomorrow or buy more now!</h4>

                <img src={warningLife} alt={""} />
                <div className="warning-okay-button">
                    <button onClick={goToStore} className='nes-btn is-warning'>Buy More</button>
                </div>
            </div>
        </div>
    )
}

export default BuyMoreLives