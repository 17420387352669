import Meta from '../../components/Meta'
import { Container } from 'react-bootstrap'

import "./UseMetamask.css"

import metamaskSetuPhoto from '../../assets/metamask_setup.jpeg'
import metamaskSteps1 from '../../assets/metamask_steps_1.png'
import metamaskSteps2 from '../../assets/metamask_steps_2.png'
import metamaskSteps3 from '../../assets/metamask_steps_3.png'
import metamaskSteps4 from '../../assets/metamask_steps_4.png'
import metamaskSteps5 from '../../assets/metamask_steps_5.png'
import metamaskSteps6 from '../../assets/metamask_steps_6.png'
import metamaskSteps7 from '../../assets/metamask_steps_7.png'
import metamaskSteps8 from '../../assets/metamask_steps_8.png'


const UseMetamask = () => {
  // page content
  const pageTitle = 'How to  Use Metamask'

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="text-page-top-margin">
        <Container className='use-metamask-mainbox'>
            <h1 className="text-center mb-6">How to Use Metamask</h1>

            <h2 className="mb-5">How to use MetaMask: A step by step guide</h2>

            <p>MetaMask is one of the most popular browser extensions that serves as a way of storing your Ethereum and other ERC-20 Tokens. The extension is free and secure, and it allows web applications to read and interact with Ethereum’s blockchain.</p>

            <img src={metamaskSetuPhoto} alt={""} />

            <h4 className="mb-5">Step 1. Install MetaMask on your browser.</h4>

            <p>
            To create a new wallet you have to install the extension first. Depending on your browser, there are different marketplaces to find it. Most browsers have MetaMask on their stores, so it’s not that hard to see it, but either way, here they are <a href='https://chrome.google.com/webstore/detail/nkbihfbeogaeaoehlefnkodbefgpgknn'>Chrome</a> , <a href='https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/'>Firefox</a>, and <a href='https://operaextensions.com/extension/metamask-extension-for-opera/'>Opera</a>.
            </p>

            <p className='mb-6'>
            In this tutorial, we will be using Google Chrome as an example but the same steps apply to different browsers as well. If, for some reason, the links above don’t work for you, just search MetaMask Extension on your favorite search engine, and you can find it there.
            </p>

            <img src={metamaskSteps1} alt={""} />

            <img className='mb-6' src={metamaskSteps2} alt={""} />

            <ul className='mb-6'>
                <li>Click on <strong>Install MetaMask</strong> as a Google Chrome extension.</li>
                <li>Click <strong>Add to Chrome.</strong></li>
                <li>Click <strong>Add Extension.</strong></li>
            </ul>

            <img src={metamaskSteps3} alt={""} />

            <img src={metamaskSteps4} alt={""} />

            <p className='mb-6'>And it’s as easy as that to install the extension on your browser, now continue reading the next step to figure out how to create an account.</p>

            <h4 className='mb-5'>Step 2. Create an account.</h4>

            <ul className='mb-6'>
                <li>Click on the extension icon in the upper right corner to open MetaMask.</li>
                <li>To install the latest version and be up to date, <strong>click Try it now.</strong></li>
                <li><strong>Click Continue.</strong></li>
                <li>You will be prompted to create a new password. <strong>Click Create.</strong></li>
            </ul>

            <img src={metamaskSteps5} alt={""} />

            <ul>
                <li>Proceed by <strong>clicking Next </strong>and accept the Terms of Use.</li>
            </ul>

            <p><strong>Click Reveal Secret Words. </strong>There you will see a 12 words seed phrase. This is really important and usually not a good idea to store digitally, so take your time and write it down.</p>

            <img src={metamaskSteps6} alt={""} />

            <img src={metamaskSteps7} alt={""} />

            <ul>
                <li>Verify your secret phrase by selecting the previously generated phrase in order. <strong> Click Confirm.</strong></li>
            </ul>

            <p className='mb-6'>And that’s it now you have created your MetaMask account successfully. A new Ethereum wallet address has just been created for you. It’s waiting for you to deposit funds, and if you want to learn how to do that, look at the next step below.</p>

            <h4 className='mb-4'>Step 3. Depositing funds.</h4>

            <ul>
                <li>Click on <strong>View Account.</strong></li>
            </ul>

            <img src={metamaskSteps8} alt={""} />
            
            <ul className='mb-6'>
                <li>You can now see your public address and share it with other people. There are some methods to buy coins offered by MetaMask, but you can do it differently as well, you just need your address.</li>
            </ul>

            <p className='mb-6'>The MetaMask extension will always be available in your browser’s toolbar, ready for you to continue the journey into the Crypto world. It’s easily accessible, and as you saw from these steps, simple to use as well. Sending coins is also very intuitive; there’s a big send button, and the rest are easily understandable. But in case something wrong happens and you need to restore your account, you have this option. First, you have to back it up, and let’s look at how we can do that.</p>

            <h5 className='mb-3'>How to backup and restore your wallet using the seed phrase?</h5>
            
            <p>Suppose you change browser or computer and want to connect your wallet again, here’s how to do it. First, you have to find your seed phrase, which you should have stored, to begin with.</p>

            <ul className='mb-5'>
                <li>Click on the <strong>Click on the</strong></li>
                <li><strong>Click Settings.</strong></li>
                <li>Find <strong>Reveal Seed Words </strong>and click it.</li>
                <li>Enter your password.</li>
            </ul>

            <p>Now save the Secret Seed Phrase somewhere, preferably not digitally. The backup is done now all you have to do is to learn the restoring process.</p>

            <ul>
                <li>Open Metamask and <strong>click Import using the account seed phrase.</strong></li>
                <li>Enter your Seed Phrase.</li>
                <li>Create a new strong password.</li>
                <li><strong>Click Restore.</strong></li>
            </ul>

            <p className='mb-6'>And that’s it now you have successfully restored your account by following these easy steps.</p>

        </Container>
      </div>
    </div>
  )
}

export default UseMetamask