import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import useSound from 'use-sound';
import { data } from "../mockData";
import GameScreen from "./GameScreen";

import correctSound from '../assets/sounds/correct.mp3'
import wrongSound from '../assets/sounds/Wrong.wav'
import timeSound from '../assets/sounds/alarm.wav'

import loader from '../assets/loader.gif'

import * as api from '../api/index'

const Game = ({ account, playMusic, pause }) => {
    // page content
    const pageTitle = 'Expart Game'

    const pauseMusic = () => {
        pause();
    }

    const [playCorrectSound] = useSound(correctSound)
    const [playWrongSound] = useSound(wrongSound)
    const [playTimeSound] = useSound(timeSound)

    const [score, setScore] = useState(0);
    const [life, setLife] = useState(3);
    // const [streak, setStreak] = useState(0);
    const [timer, setTimer] = useState(10);
    const [noLivesLeft, setNolivesLeft] = useState(false);

    let randomNo = Math.floor(Math.random() * 7) + 1;

    const [levelId, setLevelId] = useState(randomNo);
    const [level, setLevel] = useState(data[levelId]);
    const [roundOver, setRoundOver] = useState(false);
    const [roundResult, setRoundResult] = useState('s');
    const [gameOver, setGameOver] = useState(false);

    const [startTimer, setStartTimer] = useState(false)
    const [warningshown, setWarningshown] = useState(true)
    const [showWarningTimer, setShowWarningTimer] = useState(false)
    const [warningtimer, setWarningTimer] = useState(3);
    const [levelPics, setLevelPics] = useState({
        l: loader,
        r: loader
    })
    const [showHammer, setShowHammer] = useState(false)

    const history = useHistory();

    const tempUserId = Date.now().toString()
    const [username, setUsername] = useState(
        (account) ? account : tempUserId
    )
    useEffect(() => {
        let nameForUser = (account) ? account : tempUserId
        setUsername(nameForUser)
    }, [account])  // eslint-disable-line react-hooks/exhaustive-deps


    const guess = (selection) => {
        // let correctAns = level.s

        setShowHammer(true)
        let selectedAns = (selection.target.className.indexOf('rightItem') >= 0) ? 'r' : 'l'

        if (roundOver) {
            return;
        }
        // evaluateRound(correctAns, selectedAns)
        evaluateRound(selectedAns)
        setTimeout(
            () => setShowHammer(false),
            1000
        )
    }

    // const evaluateRound = (correctAns, selectedAns) => {
    //     if (selectedAns === correctAns) {
    //         console.log("correct")
    //         if (streak === 3) {
    //             setScore(previousScore => previousScore + 2)
    //         }
    //         else if (streak === 5) {
    //             setScore(previousScore => previousScore + 3)
    //         }
    //         else if (streak === 10) {
    //             setScore(previousScore => previousScore + 5)
    //         }
    //         else {
    //             setScore(previousScore => previousScore + 1)
    //         }
    //         setStreak((previousStreak) => previousStreak + 1);
    //         setRoundResult('r');
    //     } else {
    //         console.log("incorrect")
    //         setLife(previousLife => previousLife - 1)
    //         setStreak(0);
    //         setRoundResult('w');
    //         playWrongSound()
    //     }
    // }
    const evaluateRound = async (selectedAns) => {

        // console.log(selectedAns)

        let answer = await api.checkAnswer(username, selectedAns)
        answer = JSON.parse(answer.data.replace(/'/g, '"'))
        // console.log(answer)

        if (selectedAns === 's') {
            setRoundResult('s')
            playWrongSound()
            setRoundOver(true)
            setLife(answer.l - 1)
        }
        else {
            if (answer.a === selectedAns) {
                setRoundResult('r')
                playCorrectSound()
                setRoundOver(true)
            } else {
                setRoundResult('w')
                setRoundOver(true)
                playWrongSound()
                setLife(answer.l - 1)
            }
        }
        setScore(answer.s)
    }
    useEffect(() => {
        if (life === 0) {
            console.log("Game Over")
            setGameOver(true)
        }
    }, [life]);
    useEffect(() => {
        if (startTimer === true) {
            const time = (timer > 0 && !roundOver) && setInterval(() => setTimer(timer - 1), 1000);
            return () => clearInterval(time);
        }
    });
    useEffect(() => {
        if (showWarningTimer === true) {
            const warningtime = (warningtimer > 0) && setInterval(() => setWarningTimer(warningtimer - 1), 1000);
            return () => clearInterval(warningtime);
        }
    });
    useEffect(() => {
        if (timer === 0 && !roundOver) {
            // let correctAns = level.s
            let selectedAns = 's'
            playTimeSound()

            if (roundOver) {
                return;
            }
            evaluateRound(selectedAns)
            setRoundOver(true)
        }
    }, [timer]);  // eslint-disable-line react-hooks/exhaustive-deps

    const playNextGame = () => {
        let randomNo = Math.floor(Math.random() * 7) + 1;
        setLevelId(randomNo)
        setLevel(data[levelId])
        setRoundOver(false)
        setTimer(10)
        setLevelPics({
            l: loader,
            r: loader
        })
        changeLevel()
    }

    const changeLevel = async () => {
        const nextSetOfNfts = await api.getNextNFTs(username)
        let result = nextSetOfNfts.data
        result = JSON.parse(result.replace(/'/g, '"'))
       
        setLevelPics({
            l: `https://davinci.gallery/${result.l}`,
            r: `https://davinci.gallery/${result.r}`
        })
    }

    const seeResults = async () => {
        //TODO Call API again to update score
        // const nextSetOfNfts = await api.getNextNFTs(username)
        localStorage.setItem("game-score", JSON.stringify(score));
        history.push("/score");
    }

    const getMoreLives = () => {
        history.push("/summary");
    }

    const closeWarning = async () => {
        setWarningshown(false)
        setShowWarningTimer(true)
        try {
            const createdSession = await api.createGameSession(username)
            setNolivesLeft(false)
            let result = createdSession.data
            result = JSON.parse(result.replace(/'/g, '"'))   // eslint-disable-line no-unused-vars
           
            changeLevel()
            // setShowWarningTimer(true)
            setTimeout(() => {
                setShowWarningTimer(false)
                setStartTimer(true)
            }, 3000);
        } catch (error) {
            setNolivesLeft(true)
            console.log(error.message);
        }




    }

    return (
        <div>
            <GameScreen
                playMusic={playMusic}
                pauseMusic={pauseMusic}
                pageTitle={pageTitle}
                life={life}
                score={score}
                timer={timer}
                roundOver={roundOver}
                roundResult={roundResult}
                gameOver={gameOver}
                level={level}
                warningshown={warningshown}
                closeWarning={closeWarning}
                guess={guess}
                seeResults={seeResults}
                playNextGame={playNextGame}
                levelPics={levelPics}
                showHammer={showHammer}
                showWarningTimer={showWarningTimer}
                warningtimer={warningtimer}
                getMoreLives={getMoreLives}
                noLivesLeft={noLivesLeft}
            />

        </div>
    )
}

export default Game