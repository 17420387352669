import { LinkContainer } from 'react-router-bootstrap'

import "./Warning.css"

import warningLife from '../../assets/lives.png'

const Warning = ({closeWarning}) => {

    return (
        <div className="game-warning-container">
            <div className="warning-black-background">
                <h1>
                    Don't Lose These
                </h1>

                <img src={warningLife} alt={""} />
                <div className="warning-okay-button">
                    <LinkContainer to='/game'>
                        <button onClick={closeWarning} className='nes-btn is-warning'>Okay</button>
                    </LinkContainer>
                </div>
            </div>
        </div>
    )
}

export default Warning